import React from 'react'
import Navbar from '../Navbar/Navbar'
import NavbarMobile from '../Navbar/NavbarMobile'
import Footer from '../Footer/Footer'
import FloatingButton from '../FloatingButton/FloatingButton'

function MainLayout({
    moveToSection,
    children,
    setisShowBar,
    isShowBar,
    setnextTab,
    setnextTabMobile,
    textWa="service"
}) {
  return (
    <main className='relative flex h-full w-full flex-col justify-between'>
        <div className='lg:mx-auto w-full justify-center flex items-center'>
            <div className='hidden lg:mx-auto relative justify-center lg:flex w-screen'>
                <Navbar setnextTab={setnextTab} moveToSection={moveToSection}/>
            </div>
            <div className='lg:hidden flex w-full'>
                <NavbarMobile 
                    setnextTab={setnextTabMobile} 
                    setisShowBar={setisShowBar}
                    isShowBar={isShowBar} 
                    moveToSection={moveToSection} 
                />
            </div>
        </div>
        <article className='flex flex-col top-16 relative lg:top-24 w-full mx-auto'>
            {children}
        </article>
        <div className='relative top-24 lg:top-32 mx-auto w-full justify-end flex items-center'>
            <Footer setnextTab={setnextTab} moveToSection={moveToSection}/>
        </div>
        <FloatingButton textWa={textWa} />
    </main>
  )
}

export default MainLayout