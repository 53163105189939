export const contactUs = [
    {
        type: "section",
        list: {
            title: "Hubungi Kami",
            main: "2 Langkah Registrasi",
            description: "Beri tahu kami masalah keuanganmu, tim kami siap bantu."
        }
    },
    {
        type: "label-form",
        list: [
            {
                type: "name",
                text: "Nama"
            },
            {
                type: "email",
                text: "Alamat Email"
            },
            {
                type: "phone",
                text: "No. Telp"
            },
            {
                type: "industry",
                text: "Pilih Industri"
            },
            {
                type: "custom_industry",
                text: "Tulis Industri"
            },
            {
                type: "message",
                text: "Ceritakan tentang bisnis dan kebutuhan Kamu"
            },
            {
                type: "button_submit",
                text: "Kirim"
            }
        ]
    },
]